// Sweetalert2

.swal2-container {
  z-index: 1070 !important;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba($black, .6) !important;
}

.swal2-popup {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
  max-width: px(1180) !important;
  max-height: px(556) !important;
  padding: px(30) px(60) px(40) !important;
  background: url('#{$cdn}/img/bg-popup.png') 0 0/100% 100% no-repeat !important;

  .swal2-title {
    display: block !important;
    font-size: px(60.65);
    font-family: $font-family-alp;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.7em;
    margin: 0;
    width: 100%;
    color: $white;
  }

  .swal2-close {
    width: auto;
    height: auto;
    color: transparent;
    outline: none;
    top: px(-19);
    right: px(4);

    img {
      width: px(120);
    }
  }

  .popup-alert {
    &__content {
      color: $white;
    }
  }

  &.popup-error {
    .popup-alert {
      &__message {
        font-size: px(45.49);
        line-height: 1.4em;
        font-weight: normal;
        font-style: italic;
        margin: px(35) 0 0;
      }
    }
  }

  &.popup-success {
    max-width: px(1656) !important;
    max-height: px(780) !important;
    padding: px(40) px(120) px(60) !important;

    .popup-alert {
      &__message {
        font-size: px(45.49);
        line-height: 1.4em;
        font-weight: normal;
        font-style: italic;
        margin: px(35) 0 0;
      }

      &__gift {
        &--image {
          display: inline-block;
          margin-top: px(10);
          position: relative;

          img {
            max-width: 100%;
            max-height: px(425);
          }

          span {
            position: absolute;
            bottom: px(5);
            right: px(5);
            font-size: px(30);
            font-weight: 700;
            color: yellow;
            text-shadow: px(1) px(1) px(4) $black;
          }
        }

        &--message {
          font-size: px(34.4);
          margin-top: px(30);
        }

        &--name {
          font-size: px(46.39);
          font-weight: 700;
          margin-top: px(10);
          text-transform: uppercase;
        }
      }
    }
  }

  &.popup-confirm {
    .swal2-actions {
      margin: px(60) 0 0 !important;

      button {
        border: 0 !important;
        margin: 0 !important;
        padding: 0 0 px(15) !important;
        color: $white !important;
        border-radius: 0 !important;
        font-weight: 700 !important;
        box-shadow: none !important;
        text-transform: uppercase !important;
        font-size: px(28) !important;
        width: px(229) !important;
        height: px(91) !important;

        &.swal2-cancel {
          background: url('#{$cdn}/img/bg-cancel.png') 0 0/100% 100% no-repeat !important;
        }

        &.swal2-confirm {
          margin-left: px(192) !important;
          background: url('#{$cdn}/img/bg-confirm.png') 0 0/100% 100% no-repeat !important;
        }
      }
    }

    .popup-alert {
      &__content {
        .custom-control {
          margin-top: px(40);
          text-align: center;

          .custom-control-label {
            font-size: px(33.26);
            font-weight: normal;

            &:before, &:after {
              width: px(28);
              height: px(28);
              left: px(-41);
              top: px(9);
              border-radius: 0;
            }

            &:before {
              border: $primary solid 1px;
              background-color: $colorGray;
            }
          }

          .custom-control-input:checked ~ .custom-control-label::before {
            background-color: $primary;
          }
        }
      }

      &__message {
        font-size: px(45.49);
        line-height: 1.4em;
        font-weight: normal;
        font-style: italic;
        margin: px(30) 0 0;
      }
    }
  }
}
