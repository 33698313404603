// Scrollbar

.ScrollbarsCustom {
  &-Wrapper {
    right: 0 !important;
  }

  &-Track {
    right: 0 !important;
    width: px(7) !important;
    background: none !important;
    height: 100% !important;
    top: 0 !important;
    background: transparent !important;
  }

  &-Thumb {
    background: rgba($white, .7) !important;
    transition: opacity 300ms ease;
  }
}
