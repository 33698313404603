// Fonts

@font-face {
  font-family: 'ALPMassimoBold';
  src: url('/fonts/ALPMassimoBoldItalic.eot?#iefix') format('embedded-opentype'),
       url('/fonts/ALPMassimoBoldItalic.otf') format('opentype'),
       url('/fonts/ALPMassimoBoldItalic.woff') format('woff'),
       url('/fonts/ALPMassimoBoldItalic.ttf') format('truetype'),
       url('/fonts/ALPMassimoBoldItalic.svg#ALPMassimoBoldItalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ALPMassimoBold';
  src: url('/fonts/ALPMassimoBold.eot?#iefix') format('embedded-opentype'),
       url('/fonts/ALPMassimoBold.otf') format('opentype'),
       url('/fonts/ALPMassimoBold.woff') format('woff'),
       url('/fonts/ALPMassimoBold.ttf') format('truetype'),
       url('/fonts/ALPMassimoBold.svg#ALPMassimoBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
