// Modal

.ReactModal__Overlay {
  display: flex !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  z-index: 1060 !important;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch !important;
  background: rgba($black, .6) !important;
}

.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  box-sizing: border-box;
  margin: auto;
  border-radius: 0 !important;
  background: transparent;
  border: 0 !important;
  padding: 0 !important;
  outline: none;
  transform: scale(0);
  transition: transform 600ms ease;
  width: 100% !important;
  height: 100% !important;
  max-width: px(1656);
  max-height: px(858);

  &--after-open {
    transform: scale(1);
  }

  &--before-close {
    transform: scale(0);
  }

  .modal-wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    .modal-body {
      overflow: hidden;
      will-change: transform;
      height: 100%;

      .modal-content {
        width: 100%;
      }
    }
  }

  .close {
    position: absolute;
    top: px(-19);
    right: px(4);
    opacity: 1 !important;
    outline: none !important;
    z-index: 9;
    cursor: pointer;
    display: inline-block;
    line-height: 1em;

    img {
      width: px(120);
    }
  }

  // DEFAULT
  &.modal-theme-default {
    .modal-wrapper {
      .modal-body {
        padding: px(40) px(120) px(60);
        background: url('#{$cdn}/img/bg-popup.png') 0 0/100% 100% no-repeat;

        .ScrollbarsCustom {
          height: 100% !important;

          &-Track {
            right: px(-30) !important;
            height: calc(100% - #{px(160)}) !important;
            top: px(160) !important;
          }
        }

        .modal-title {
          font-size: px(60.65);
          font-family: $font-family-alp;
          text-align: center;
          text-transform: uppercase;
          line-height: 1.7em;
          padding-bottom: px(30);
          margin: 0 0 px(20);
          color: $white;
          background: url('#{$cdn}/img/decor-line.png') bottom center/px(1009) px(43) no-repeat;
        }

        .modal-content {
          font-size: px(24);
          font-style: italic;
          font-weight: 500;
        }
      }
    }
  }
}
