// Coupon

.coupon {
  color: $colorPink;
  font-family: $font-family-alp;
  width: px(251, .9);
  height: px(86, .9);
  position: relative;
  background: url('#{$cdn}/img/bg-coupon-result.png') 0 0/100% 100% no-repeat;

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin-top: px(-11, .9);
    transform: translate(-50%, -50%);
  }

  &__turn {
    font-size: px(22);

    &--label {
      display: inline-block;
      transform: rotate(-10deg) translate(px(-3), px(6));
    }
  }

  &__price {
    font-size: px(27);
    line-height: 1em;
    display: inline-block;
    margin-left: px(5);

    &--label {
      img {
        width: px(30);
      }
    }

    &--value {
      display: inline-block;
      transform: rotate(10deg) translate(px(3), px(5));
    }
  }
}
