// Score Board

.score-board {
  display: flex;
  position: relative;
  width: px(756);
  height: px(102);
  padding: px(15) px(18);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: url('#{$cdn}/img/bg-score.png') 0 0/100% 100% no-repeat;
  }

  &__left, &__right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-family-alp;
    font-size: px(22);
    line-height: 1em;
    padding-top: px(5);
    transition: all 600ms ease;

    span {
      font-size: px(47);
    }
  }

  &__left {
    position: relative;
    background: $colorPink;
    border-radius: px(20) 0 0 px(20);
    padding-right: px(30);

    span {
      margin-left: px(10);
    }

    &:before {
      content: '';
      position: absolute;
      top: px(-24);
      left: calc(100% - #{px(50)});
      width: px(112);
      height: px(121);
      z-index: 3;
      background: url('#{$cdn}/img/icon-star.png') 0 0/100% 100% no-repeat;
    }
  }

  &__right {
    background: $colorBlue;
    border-radius: 0 px(20) px(20) 0;
    padding-left: px(30);

    span {
      margin-right: px(10);
    }
  }

  &[data-side="left"] {
    .score-board__left {
      width: 55%;
    }

    .score-board__right {
      width: 45%;
    }
  }

  &[data-side="right"] {
    .score-board__left {
      width: 45%;
    }

    .score-board__right {
      width: 55%;
    }
  }
}
