// Header

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: px(46) px(154) 0 px(43);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;

  &__left {
    a {
      margin-right: px(22);

      img {
        height: px(74);
      }
    }
  }

  &__right {
    text-align: center;
  }

  &__user {
    font-size: px(26.87);
    line-height: 1.1em;

    strong {
      font-weight: 700;
    }
  }

  &__coupon {
    font-size: px(35.21);
    font-weight: 700;
    line-height: 1.1em;
    margin-top: px(10);

    img {
      width: px(34);
      margin-right: 7px;
    }
  }
}
