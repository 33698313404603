// Balance

.balance {
  $self: '.balance';

  text-align: center;
  position: relative;
  width: px(1476);
  margin: 0 auto;

  // Content
  &__content {
    position: absolute;
    bottom: calc(100% + #{px(110)});
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &__score {
    display: flex;
    justify-content: center;
  }

  &__description {
    font-size: px(20);
    line-height: 1.2em;
    margin-top: px(30);
  }

  &__teams {
    font-family: $font-family-alp;
    font-size: px(23.89);
    margin-top: px(10);

    &--left {
      color: $colorPink;
    }

    &--right {
      color: $colorBlue;
    }
  }

  // Left Right Cards
  &__left, &__right {
    position: absolute;
    z-index: 2;
    transition: all 600ms ease;
  }

  &__left {
    bottom: calc(100% - #{px(25)});
    left: px(-230);
  }

  &__right {
    bottom: calc(100% - #{px(10)});
    right: px(-200);
  }

  &__main {
    position: relative;

    &--head {
      position: absolute;
      top: px(-90);
      left: 0;
      width: 100%;
      z-index: 1;
      transition: all 600ms ease;
    }

    &--foot {
      width: px(247);
    }
  }

  // Dialog
  &__dialog {
    position: absolute;
    z-index: 100;
    transition: all 600ms ease;

    &:before {
      content: '';
      position: absolute;
      background: 0 0/100% 100% no-repeat;
      width: px(48);
      height: px(50);
    }

    &--left {
      right: calc(50% + #{px(171)});
      bottom: px(49);

      &:before {
        bottom: 100%;
        right: px(-87);
        background-image: url('#{$cdn}/img/icon-dialog-line-left.png');
      }
    }

    &--right {
      left: calc(50% + #{px(150)});
      bottom: px(70);

      &:before {
        bottom: calc(100% - #{px(11)});
        left: px(-81);
        background-image: url('#{$cdn}/img/icon-dialog-line-right.png');
      }
    }
  }

  // Animation
  &[data-side="left"] {
    #{$self}__main--head {
      transform: rotate(-7deg);
    }

    #{$self}__left {
      transform: translateY(px(80));
    }

    #{$self}__right {
      transform: translateY(px(-80));
    }

    #{$self}__dialog--left {
      transform: translate(px(15), px(20));
    }

    #{$self}__dialog--right {
      transform: translate(px(15), px(-20));
    }
  }

  &[data-side="right"] {
    #{$self}__main--head {
      transform: rotate(7deg);
    }

    #{$self}__left {
      transform: translateY(px(-80));
    }

    #{$self}__right {
      transform: translateY(px(80));
    }

    #{$self}__dialog--right {
      transform: translate(px(5), px(10));
    }

    #{$self}__dialog--left {
      transform: translate(px(5), px(-10));
    }
  }
}
