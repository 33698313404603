// Stacks

.stacks {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column-reverse;
  width: px(47);
  height: px(314);
  padding-bottom: px(5);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('#{$cdn}/img/bg-stacks-border.png') 0 0/100% 100% no-repeat;
  }

  &__item {
    width: px(29);
    margin-top: px(-4);
  }
}
