// Gift Item

.gift-item {
  $self: '.gift-item';

  &__info {
    overflow: hidden;
    position: relative;

    &--image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--name {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.2em;
    }

    &--description {
      position: absolute;
      font-weight: 700;
      color: yellow;
      text-shadow: px(1) px(1) px(4) $black;
    }

    &:hover {
      #{$self}__info--name {
        color: $white;
        background: rgba($black, .7);
      }
    }
  }

  &[data-theme="default"] {
    width: px(135);
    height: px(135);
    padding: px(11) px(18) px(23) px(15);
    background: url('#{$cdn}/img/bg-gift-item.png') 0 0/100% 100% no-repeat;

    #{$self}__info {
      border-radius: px(12);

      &--name {
        font-size: px(16);
        padding: px(5);
      }

      &--description {
        bottom: px(5);
        right: px(5);
        font-size: px(20);
      }
    }

    &.active {
      opacity: .4;
    }
  }

  &[data-theme="left"], &[data-theme="right"] {
    width: px(49);
    height: px(47);
    padding: px(2) px(4) px(2) px(3);

    #{$self}__info {
      border-radius: px(4);
      width: 100%;
      height: 100%;

      &--name {
        display: none;
      }

      &--image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &--description {
        bottom: px(1);
        right: px(2);
        font-size: px(12);
      }
    }

    &.active {
      opacity: .6;
    }
  }

  &[data-theme="left"] {
    background: url('#{$cdn}/img/bg-gift-item-left.png') 0 0/100% 100% no-repeat;
  }

  &[data-theme="right"] {
    background: url('#{$cdn}/img/bg-gift-item-right.png') 0 0/100% 100% no-repeat;
  }
}
