// Welcome

.modal-content {
  .welcome {
    $self: '.welcome';

    text-align: center;

    &__title {
      margin-bottom: px(40);

      img {
        width: px(611);
      }
    }

    &__guides {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__guide {
      width: px(410);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-self: stretch;

      &--content {
        margin-bottom: px(25);

        strong {
          font-style: normal;
        }
      }

      &--image {
        width: px(288);
        height: px(285);
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      &:first-child {
        #{$self}__guide--image {
          img {
            max-width: px(216);
          }
        }
      }
    }
  }
}
