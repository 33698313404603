// Card

.card {
  $self: '.card';

  display: block;
  position: relative;
  background-position: 0 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  animation: twinkle 2000ms infinite;

  &.loading {
    pointer-events: none !important;
  }

  &__stacks, &__coupon, &__gifts {
    position: absolute;
  }

  &__gifts {
    .gift-item ~ .gift-item {
      margin-top: px(5);
    }
  }

  &__left {
    width: px(529);
    height: px(721);

    #{$self}__stacks {
      top: px(148);
      right: px(117);
    }

    #{$self}__coupon {
      bottom: px(102);
      right: px(105);
    }

    #{$self}__gifts {
      top: px(120);
      left: px(65);
    }
  }

  &__right {
    width: px(548);
    height: px(659);

    #{$self}__stacks {
      top: px(111);
      left: px(160);
    }

    #{$self}__coupon {
      bottom: px(85);
      left: px(145);
    }

    #{$self}__gifts {
      top: px(85);
      right: px(45);
    }
  }
}
