// Home

.page-home {
  &__logo {
    position: absolute;
    top: px(34);
    left: calc(50% - #{px(800 / 2)});
    width: px(800);
    z-index: 200;

    img {
      width: 100%;
    }
  }

  &__balance {
    position: absolute;
    bottom: px(23);
    left: 0;
    width: 100%;
  }
}
