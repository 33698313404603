// Dialog

.dialog {
  color: $white;
  font-size: px(25.12);
  font-style: italic;
  font-weight: 500;
  line-height: 1.2em;
  background: url('#{$cdn}/img/bg-dialog.png') 0 0/100% 100% no-repeat;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &[data-size="lg"] {
    width: px(621);
    height: px(119);
    padding: 0 px(35);
  }

  &[data-size="sm"] {
    width: px(435);
    height: px(95);
    padding: 0 px(20);
  }

  &__close {
    display: inline-block;
    position: absolute;

    &--left {
      top: px(-15);
      left: px(-15);
    }

    &--right {
      top: px(-17);
      right: px(-9);
    }

    img {
      width: px(43);
    }
  }
}
